
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































































































.campaign-setup-links-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: $--clb-border-radius;
  transition: $--all-transition, max-height 500ms ease-in-out, min-height 300ms ease-out;
  padding: $--clb-space-4;
  cursor: pointer;

  &__top {
    display: flex;
    align-items: center;
  }

  &__information {
    font-size: $--clb-font-size-xs !important;
    padding: 0 $--clb-space-1;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__title {
    flex: 3 1 0;
    font-size: $--clb-font-size-sm !important;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
    }

    &-name {
      margin-right: $--clb-space-2;
      font-weight: 600;
    }

    &-redirectless {
      position: relative;
      top: -2px;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      border-radius: $--clb-space-1;
      color: $--clb-body-font;
      white-space: nowrap;
      padding: $--clb-space-1 $--clb-space-1;
      background: #edf1ff;
      border: 1px solid $--clb-shadow-color;
    }

    &-badge {
      position: relative;
      top: -2px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }
  }

  &__buttons {
    min-width: 60px + $--clb-space-2;
    text-align: right;

    .el-button-group {
      .el-button {
        margin: 0;
        padding: 0;
        height: 36px;
        width: 36px;
        font-size: $--clb-font-size-xs;

        span {
          display: none;
        }

        i {
          font-size: $--clb-font-size-sm;
        }

        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.75);
        }

        &:hover,
        &:active,
        &:focus {
          transform: translateY(0);
        }
      }
    }
  }

  &__bottom {
    padding-bottom: $--clb-space-3;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .app-node-strip {
      margin-left: $--clb-layout-2;
      position: relative;
      top: -14px;
    }
  }

  &:hover {
    box-shadow: $--box-shadow-light;
    transform: translateY(-1px);
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;
  }

  &:active,
  &.active,
  &:focus {
    border-color: $--jb-dark-primary-color;
    background: $--color-light;
    outline: none;
    transform: translateY(0);
    color: $--jb-dark-primary-color;
  }

  &--active {
    min-height: 150px;
    max-height: 1000px;
  }
}

@media (min-width: $--sm) {
  .campaign-setup-links-item {
    &__top {
      flex-direction: row;
    }

    &__title {
      font-size: $--clb-font-size-base !important;
    }

    &__information {
      font-size: $--clb-font-size-sm !important;
    }

    &__buttons {
      flex-direction: row;
      min-width: 200px + $--clb-space-2;
      justify-content: flex-end;
      padding: 0;

      .el-button-group {
        .el-button {
          width: 100px;
          height: 36px;

          span {
            display: inline;
          }
        }
      }
    }

    &__bottom {
      align-items: center;

      .app-node-strip {
        margin-left: 0;
        position: static;
      }
    }
  }
}
