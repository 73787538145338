
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




























































































































































































.campaign-setup-links {
  @include stage-layout;

  @apply tw-mt-0 tw-p-layout-1 md:tw-p-0 tw-bg-transparent #{!important};

  &-header {
    display: flex;
    margin: $--clb-space-4 0 $--clb-space-2;
    transition: $--all-transition;
    text-align: center;
    align-items: center;

    p {
      font-size: $--clb-font-size-xs;
      font-weight: 700;
      flex: 1 1 0;
    }

    &__left {
      flex: 3;
      text-align: left;
    }

    &__middle {
      flex: 1;
    }

    &__right {
      flex: 1;
    }

    .campaign-setup-links__title {
      margin: 0;
    }
  }

  &__item {
    border-radius: $--clb-border-radius;
    margin-bottom: $--clb-space-3 !important;

    @apply tw-bg-white;
  }

  &__normal {
    .skeleton-box {
      margin: 21px $--clb-space-3;
    }
  }
}
