
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































































































































































.app-lightbox {
  transition: all 3s ease;

  .el-dialog {
    height: calc(100vh - 220px);
    height: calc(100 * var(--vh, 1vh) - 220px);
    background: transparent;
    box-shadow: none;
    margin: auto;
    margin-top: 130px !important;
    width: $--clb-app-content-width;
    max-width: calc(100% - #{$--clb-layout-3 * 2});

    &__header {
      padding: 0;

      button {
        position: fixed;
        right: $--clb-mobile-padding;
        background: #7f7f7f;
        border-radius: 4px;
        border: 1px solid $--clb-border-color-base;
        font-size: $--clb-font-size-lg;
        padding: 0 $--clb-space-2 - 2;
        height: 36px;
        width: 36px;
        top: 12px;
        transition: 0.2s ease-in;

        i {
          color: $--clb-border-color-base !important;
          transition: 0.2s ease-in;
        }

        &:hover {
          border-color: $--clb-color-primary;
          background: $--clb-color-primary__white;
          transform: scale(1.1);

          i {
            color: $--clb-color-primary !important;
          }
        }
      }
    }

    .app-lightbox__title {
      width: 100%;
      width: calc(100% + 196px);
      margin: 0 0 $--clb-layout-1 0;
      padding: 0 $--clb-layout-2;
      font-size: $--clb-font-size-lg;
      text-align: center;
      color: $--clb-color-primary__white;
      text-shadow: 0 0 2px rgb(33, 33, 33);
      position: relative;
      word-break: break-word;
    }

    &__body {
      user-select: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: $--clb-border-radius;

      &-img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }

      .app-lightbox--image-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;

        &--error {
          background: #e0e0e0;
          width: 100%;
          height: 100%;

          > img,
          > video {
            margin: auto;
          }
        }
      }

      .app-lightbox--loading {
        height: 100%;
        min-height: 60px;
        width: 60px;

        .el-loading-mask {
          background: none;
        }

        circle {
          stroke: $--border-color-base;
        }
      }
    }
  }

  &--navigate {
    .el-dialog {
      padding: 0 88px;
      width: 100%;

      @include xs-only {
        padding: $--clb-layout-4;
      }
    }
  }

  .app-lightbox__left {
    left: 0;
    justify-content: flex-start;

    .app-lightbox--arrow {
      margin-left: $--clb-space-6;

      @include xs-only {
        margin-left: $--clb-space-2;
      }
    }
  }

  .app-lightbox__right {
    right: 0;
    justify-content: flex-end;

    .app-lightbox--arrow {
      margin-right: $--clb-space-6;

      @include xs-only {
        margin-right: $--clb-space-2;
      }
    }
  }

  .app-lightbox__left,
  .app-lightbox__right {
    display: flex;
    align-items: center;
    position: absolute;
    width: 50%;
    height: 100%;
    cursor: pointer;

    .app-lightbox--arrow {
      width: 40px;
      cursor: pointer;
      border-radius: 100%;
      border: 1px solid $--border-color-base;
      color: $--border-color-base;
      font-size: $--clb-font-size-xl;
      line-height: $--clb-space-5;
      padding: $--clb-space-2;
      background: transparent;
      box-shadow: 0 0 6px 0 $--clb-shadow-color;
      transition: $--clb-hover-transition;
      outline: transparent;
      z-index: 10;

      @include xs-only {
        width: $--clb-layout-3;
        padding: $--clb-space-1;
      }
    }

    &:hover .app-lightbox--arrow,
    .app-lightbox--arrow:hover {
      transform: scale(1.1);
      text-decoration: none;
      color: $--jb-dark-primary-color;
      border-color: $--jb-dark-primary-color;
      background: $--clb-color-primary__white;
    }

    &:hover:active .app-lightbox--arrow,
    .app-lightbox--arrow:hover:active {
      transform: scale(1);
      background: $--color-light;
    }
  }

  .app-lightbox--error {
    text-align: center;
    color: $--clb-color-primary__white !important;
    font-weight: 700;
    text-shadow: 0 0 2px rgb(33, 33, 33);
    z-index: 1;

    p {
      font-size: $--clb-font-size-xl;
      line-height: $--clb-p__line-height;
      margin-bottom: $--clb-space-2;
    }
  }

  .app-lightbox--link {
    margin-top: $--clb-layout-1;
    text-align: center;
    color: $--clb-color-primary__white !important;
    font-weight: 700;
    cursor: pointer;
    text-shadow: 0 0 2px rgb(33, 33, 33);
    z-index: 1;
  }

  @media (min-width: $--xs) {
    .el-dialog {
      &__header {
        button {
          right: $--clb-space-6;
        }
      }
    }
  }

  @media (min-width: $--sm) {
    .el-dialog {
      margin-top: 16.5vh !important;
      height: 66vh;
      height: calc(66 * var(--vh, 1vh));
    }
  }
}
