
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































































































.campaign-setup-links-main {
  display: flex;
  height: auto;

  @apply tw-p-layout-1 tw-bg-white tw-rounded tw-mb-layout-3;

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: $--clb-layout-1;
  }

  .campaign-setup-links-main__title {
    font-weight: 600;
    font-size: $--clb-font-size-base;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
    }

    &-name {
      margin-right: $--clb-space-4;
    }

    &-redirectless {
      position: relative;
      top: -3px;
      font-weight: normal;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      border-radius: $--clb-space-1;
      color: $--clb-body-font;
      white-space: nowrap;
      padding: $--clb-space-1 $--clb-space-1;
      background: #edf1ff;
      border: 1px solid $--clb-shadow-color;
    }

    &-badge {
      position: relative;
      top: -2px;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }

    &-badge.el-tag--green {
      top: -3px;
      background: #81cf74;
      border: #81cf74;
    }
  }

  &__nodes {
    display: flex;
  }

  &__event-count {
    color: $--clb-color-primary;
    font-size: 14px !important;
  }

  .app-node-strip,
  &__buttons {
    margin-top: $--clb-layout-1;
  }

  .el-button-group {
    width: 100%;
    transition: none;

    .campaign-setup-links-main__button {
      display: inline-block;
      margin: 0;
      height: 40px;
      width: 50% !important;
      font-size: $--clb-font-size-xs;
      transition: $--all-transition, width 0ms;

      i {
        font-size: $--clb-font-size-sm;
      }

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.75);
        margin-right: 0 !important;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }
  }

  @media (min-width: $--xs) {
    &__content {
      padding-left: $--clb-layout-4;
    }

    .app-node-strip,
    &__buttons {
      margin-top: $--clb-layout-2;
    }

    .el-button-group {
      width: auto;
    }
  }

  @media (min-width: $--sm) {
    .campaign-setup-links-main__title {
      font-weight: 600;
      font-size: $--clb-font-size-lg;
    }
  }

  @media (min-width: $--md) {
    .el-button-group {
      .campaign-setup-links-main__button {
        font-size: $--clb-font-size-sm;
        width: 128px !important;
      }
    }
  }
}
