
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




























































.campaign-setup-links-subids {
  @apply tw-bg-white tw-rounded tw-p-layout-1 tw-mb-layout-3;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    .el-icon-info {
      padding-left: $--clb-space-1;
      color: $--clb-color-accent__dark;
      font-size: $--clb-font-size-base;
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-item {
      width: 30%;
      width: calc(33% - #{$--clb-space-3});
      display: flex;
      flex-direction: column;

      label {
        display: block;
      }

      input {
        margin-top: $--clb-space-1;
      }
    }
  }
}
